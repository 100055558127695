import { SxStyleProp } from 'theme-ui';

export const styles = {
  content: {
    height: ['unset', 'unset', '75vh !important'],
    minHeight: ['unset', 'unset', '75vh !important'],
    '.content': {
      '& > div': {
        flexDirection: ['column !important', 'column !important', 'row !important'],
      },
    },
    h1: {
      fontSize: ['l', 'xl', 'xxl'],
    },
    '.paragraph': {
      fontSize: ['xxs', 'xs', 's'],
    },
  } as SxStyleProp,

  container: {
    height: ['26rem', '32rem', '35rem'],
    width: ['26rem', '32rem', '35rem'],
    backgroundColor: 'muted',
    borderRadius: 999,
    overflow: ['unset', 'unset', 'hidden'],

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    position: 'relative',
    marginLeft: [0, 0, -9, 0],
    marginTop: [5, 5, 0],
  } as SxStyleProp,

  calendlyButton: {
    color: 'text',
    borderRadius: 999,
  },

  offer: {
    padding: [0, 0, 4],
    margin: [5, 5, 8],
    height: ['20rem', '23rem', '25rem'],
    flexDirection: 'column',
    h2: {
      textAlign: 'center',
      fontSize: ['m', 'm', 'l'],
      my: 2,
    },
    svg: {
      height: 7,
    },
  } as SxStyleProp,

  titleCol: {
    flexDirection: 'column',
    alignItems: 'center',
  } as SxStyleProp,

  paragraph: {
    width: ['20rem', '20rem', '100%'],
    fontSize: ['xxs', 'xs', 's'],
    padding: 0,
    textAlign: 'center',
    marginTop: 4,
    mx: 'auto',
  } as SxStyleProp,

  link: {
    margin: 'auto',
    span: {
      bg: 'background',
      margin: 'auto',
      color: 'text',

      borderRadius: 999,
      padding: ['0.5rem 2rem'],

      '&:active': {
        bg: 'background',
      },
    },
  },
};

import { SxStyleProp } from 'theme-ui';
import { compactSectionContentPadding } from '@themed';

const sectionStyles = {
  height: ['unset', 'unset', '55vh !important', '85vh !important'],
  minHeight: ['unset', 'unset', '55vh !important', '85vh !important'],
};

export const styles = {
  container: {
    position: 'relative',
    bg: 'muted',

    ...sectionStyles,
    '.content': {
      ...sectionStyles,
    },
  } as SxStyleProp,

  content: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    padding: compactSectionContentPadding,
  } as SxStyleProp,

  packageRow: {
    backgroundColor: 'muted',
    height: '100%',
    width: ['none', 'none', '100%'],
    flexDirection: ['column', 'column', 'row'],
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'relative',
  } as SxStyleProp,

  bottomText: {
    fontWeight: 'bold',
    textAlign: 'center',
    width: ['20rem', '20rem', '100%'],
  } as SxStyleProp,
};

export const calendlyStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: 'max-content',

    position: ['relative', 'relative', 'absolute'],
    bottom: ['auto', 'auto', '50px'],
    zIndex: 999,
  } as SxStyleProp,

  button: {
    width: 'max-content',
    color: 'text',
  },
};

export const packageStyles = {
  container: {
    position: 'relative',
    float: 'left',
    width: ['22rem', '22rem', '16rem', '21rem'],
    height: ['22rem', '22rem', '16rem', '21rem'],
    marginTop: [4, 4, 0],
    marginBottom: [4, 4, 0],
  } as SxStyleProp,

  content: (backgroundColor: string) => ({
    backgroundColor,
    borderRadius: '100%',
    width: '100%',
    height: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 5,

    backfaceVisibility: 'hidden',
    '-webkit-backface-visibility': 'hidden',
    '-moz-backface-visibility': 'hidden',
    '-ms-backface-visibility': 'hidden',
  }) as SxStyleProp,

  button: (backgroundColor: string) => ({
    backgroundColor,
    color: 'text',
    borderRadius: 999,
    height: '2rem',
    width: '2rem',

    display: 'flex',
    alignItems: 'center',

    svg: {
      height: '1rem',
      width: '1rem',
    },
  }),

  title: {
    width: 'min-content',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: ['s', 'xs', 'xxs', 's'],
  } as SxStyleProp,

  price: {
    padding: 0,
    fontSize: ['xxs', 'xs', 'xxs', 'xxs'],
  },

  description: {
    fontSize: ['xxs', 'xs', 'xxxs', 'xxs'],
    textAlign: 'center',
    width: '100%',
    padding: 0,

    li: {
      fontSize: ['xxs', 'xs', 'xxxs', 'xxs'],
      margin: '5px 0px !important',
      paddingLeft: '0px !important',
      '&:before': {
        backgroundColor: 'transparent !important',
      },
    },
  } as SxStyleProp,
};
